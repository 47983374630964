
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  @include for-desktop-up {
    flex-direction: row;
    padding-inline: 1rem;
  }

  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }

    @include for-desktop-up {
      border-radius: var(--br-medium);
      overflow: hidden;
      &.withFilter {
        img {
          filter: grayscale(100%);
          transition: all 0.25s;
        }

        img:hover {
          filter: grayscale(0%);
        }
      }
    }
  }
}
